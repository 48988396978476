slider-component {
  position: relative;
  display: block;
}

@media screen and (max-width: 989px) {
  .no-js slider-component .slider {
    padding-bottom: 3rem;
  }
}

.slider__slide {
  scroll-snap-align: start;
  flex-shrink: 0;
}

@media screen and (max-width: 749px) {
  .slider.slider--mobile {
    position: relative;
    flex-wrap: inherit;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-left: 1rem;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;
  }

  .slider.slider--mobile .slider__slide {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 989px) {
  .slider.slider--tablet {
    position: relative;
    flex-wrap: inherit;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-left: 1rem;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;
  }

  .slider.slider--tablet .slider__slide {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

/* Scrollbar */

.slider {
  scrollbar-color: rgb(var(--color-foreground)) rgba(var(--color-foreground), 0.04);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider::-webkit-scrollbar {
  height: 0.4rem;
  width: 0.4rem;
  display: none;
}

.no-js .slider {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.no-js .slider::-webkit-scrollbar {
  display: initial;
}

.slider::-webkit-scrollbar-thumb {
  background-color: rgb(var(--color-foreground));
  border-radius: 0.4rem;
  border: 0;
}

.slider::-webkit-scrollbar-track {
  background: rgba(var(--color-foreground), 0.04);
  border-radius: 0.4rem;
}

.slider-counter {
  margin: 0 1.2rem;
}

.slider-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 990px) {
  .slider-buttons {
    display: none;
  }
}

@media screen and (min-width: 750px) {
  .slider--mobile + .slider-buttons {
    display: none;
  }
}

.slider-button {
  color: rgba(var(--color-foreground), 0.75);
  background: transparent;
  border: none;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.slider-button:not([disabled]):hover {
  color: rgb(var(--color-foreground));
}

.slider-button .icon {
  height: 0.6rem;
}

.slider-button[disabled] .icon {
  color: rgba(var(--color-foreground), 0.3);
}

.slider-button--next .icon {
  margin-right: -0.2rem;
  transform: rotate(-90deg) translateX(0.15rem);
}

.slider-button--prev .icon {
  margin-left: -0.2rem;
  transform: rotate(90deg) translateX(-0.15rem);
}

.slider-button--next:not([disabled]):hover .icon {
  transform: rotate(-90deg) translateX(0.15rem) scale(1.07);
}

.slider-button--prev:not([disabled]):hover .icon {
  transform: rotate(90deg) translateX(-0.15rem) scale(1.07);
}
